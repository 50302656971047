<template>
    <div style="width: 100%; height: 100%">
        <div id="map"></div>
        <small v-if="poorAccuracy" class="accuracy-indicator"
            >Poor location accuracy</small
        >
        <LoadingComponent v-if="loading" />
        <!-- <div
            style="
                position: fixed;
                z-index: 1000000;
                color: #fff;
                top: 50%;
                left: 50%;
            "
        >
            <div>{{ activePool ? activePool.name : "" }}</div>
            <div>
                {{
                    activeDropLocation
                        ? activeDropLocation.placeName
                        : "not in dropzone"
                }}
            </div>
        </div> -->
    </div>
</template>

<script lang="ts">
import { addDropZoneCircles } from "@/utils/geolocationTools"
import mapboxgl from "mapbox-gl"
import "mapbox-gl/dist/mapbox-gl.css"
import { DropLocation, DropPool } from "@/types/dropPool"
import LoadingComponent from "@/components/utils/LoadingComponent.vue"
import { VItem } from "@/types/vItem"
import { defineComponent } from "vue"
import { Action } from "@/types/customPage"
import { dropPoolStore } from "@/store/pinia_modules/dropPoolStore"
import { vItemStore } from "@/store/pinia_modules/vItemStore"
import { navigationStore } from "@/store/pinia_modules/navigationStore"
import { poolSegmentStore } from "@/store/pinia_modules/poolSegmentStore"
import { authStore } from "@/store/pinia_modules/authStore"
import { campaignStore } from "@/store/pinia_modules/campaignStore"
import { categoryStore } from "@/store/pinia_modules/categoryStore"
import { v4 as uuidv4 } from "uuid"
// import { template } from "@/templates/marker.ts";

const myAccessToken = import.meta.env.VITE_APP_MAPBOX_ACCESS_TOKEN

export default defineComponent({
    props: {
        element: {
            type: Object,
            required: true,
        },
    },
    components: { LoadingComponent },
    data() {
        return {
            // loading: false,
            watchId: null as number | null,
            reachedAccuracy: false,
            itemsDropped: false,
            userMarker: null as mapboxgl.Marker | null,
            locations: [] as DropLocation[],
            dropType: "pin",
            map: null as mapboxgl.Map | null,
            markers: [] as mapboxgl.Marker[],
            locationMarkers: {} as { [key: string]: mapboxgl.Marker },
            locationMarkersOnScreen: {} as { [key: string]: mapboxgl.Marker },
            vatMarkers: [] as mapboxgl.Marker[],
            showLocationsAndVats: false,
            selectedMarkerIndex: "",
            arButton: null as HTMLDivElement | null,
            startActiveDropPoolCheck: false,
            poorAccuracy: false,
            initZoom: true,
            vatsAdded: false,
            loadedLocations: false,
            mapStyle: "mapbox://styles/mapbox/streets-v11",
            userManualMapControl: false,
            mapZooming: false,
            mapZoomDuration: 3000,
        }
    },
    mounted(): void {
        this.hideARBtn()
        const campaign = campaignStore().getItem
        if (campaign && campaign.mapStyle && campaign.mapStyle != "") {
            this.mapStyle = campaign.mapStyle
        }
        this.loadedLocations = false
        // this.resetDropPoolLocationState();
        this.init()
        // dropPoolStore().loading = true;

        // //Get user location
        // this.initUserLocation();
        // this.setGetLocationTimeout();
    },
    beforeUnmount() {
        this.stopWatchingPosition()
        if (this.map) {
            this.poorAccuracy = false
            this.map.remove()
            this.map = null
            this.startActiveDropPoolCheck = false
        }
    },
    watch: {
        userLocation(to: [number, number], from: [number, number]) {
            if (to && from) {
                dropPoolStore().saveActiveDropPool()
                this.moveUserIcon()
            }
        },
        action(to: Action): void {
            if (to) {
                if (to.type == "claim-item") {
                    this.claimItem()
                } else if (to.type == "center-map") {
                    this.updateUserLocation()
                }
            }
        },

        activePool(to: DropPool, from: DropPool) {
            if (to && from && this.startActiveDropPoolCheck) {
                this.addRemoveDroppedItems(from.id! || "")
            }
        },

        activeDropLocation(to: DropLocation) {
            if (this.startActiveDropPoolCheck) {
                if (this.activePool) {
                    this.addRemoveDroppedItems(this.activePool.id!)
                    this.removeRadiusCircleLayer()
                    if (this.map!.getZoom() >= 14) {
                        this.loadRadiusCircleLayer(to)
                    }
                } else {
                    this.removeItemsFromMap()
                }
            }
        },
    },
    methods: {
        startWatchingPosition() {
            if ("geolocation" in navigator) {
                const options = {
                    enableHighAccuracy: true,
                    timeout: 5000,
                    maximumAge: 2000,
                }

                this.watchId = navigator.geolocation.watchPosition(
                    (position) => {
                        const latitude = position.coords.latitude
                        const longitude = position.coords.longitude
                        const accuracy = position.coords.accuracy

                        // Update stores with new position
                        dropPoolStore().saveUserLocation([longitude, latitude])
                        dropPoolStore().saveLocationAccuracy(accuracy)
                    },
                    (error) => {
                        console.error("Error watching position:", error.message)
                        this.poorAccuracy = true
                    },
                    options
                )
            }
        },
        // Add this cleanup method
        stopWatchingPosition() {
            if (this.watchId !== null) {
                navigator.geolocation.clearWatch(this.watchId)
                this.watchId = null
            }
        },
        async init() {
            this.poorAccuracy = false
            this.startActiveDropPoolCheck = false
            this.initZoom = true
            this.vatsAdded = false

            //clear all the items
            vItemStore().clearItems()
            this.resetDropPoolLocationState()
            this.startWatchingPosition()

            //Get pools categories and prizes
            await this.getPoolsCategoriesPrizes()
            //Get campaign segments
            // await this.getCampaignSegments();
            //get active dropPool if one exists
            dropPoolStore().saveActiveDropPool()
            //Get locations
            this.getLocations()
            //Check droptype
            this.getDroptype()
            dropPoolStore().setLoading(false)
            this.initMap()
            this.addUserMarker()

            const timeoutInterval = setInterval(async () => {
                // if (this.locationAccuracy < 100) {
                if (this.locationAccuracy < 5000000) {
                    if (this.map) {
                        clearInterval(timeoutInterval)
                        this.poorAccuracy = false
                        await this.dropItems()
                        this.mapZooming = true
                        this.map.flyTo({
                            center: this.userLocation,
                            essential: true,
                            duration: this.mapZoomDuration,
                            zoom: 18,
                        })
                        // wait for the map to zoom
                        setTimeout(() => {
                            this.mapZooming = false
                        }, this.mapZoomDuration)
                    }
                } else {
                    this.poorAccuracy = true
                }
            }, 3000)
        },
        geoJson() {
            let geoJSON = {
                type: "FeatureCollection",
                crs: {
                    type: "name",
                    properties: { name: "urn:ogc:def:crs:OGC:1.3:CRS84" },
                },
                features: [],
            }
            geoJSON.features = this.locations.map((location) => {
                const id = uuidv4()
                location._id = id
                return {
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [location.longitude, location.latitude],
                    },
                    properties: {
                        id: id,
                        title: location.placeName,
                        description: location.placeName,
                    },
                } as never
            })
            return geoJSON
        },
        resetDropPoolLocationState() {
            dropPoolStore().saveLocationAccuracy(100000000)
            dropPoolStore().resetActiveDropPool()
        },
        //Hide AR BTN
        hideARBtn() {
            this.arButton = document.querySelector("#ar")
            if (this.arButton) {
                this.arButton.style.pointerEvents = "none"
                this.arButton.style.opacity = "0"
            }
        },
        showARBtn() {
            if (this.arButton) {
                this.arButton.style.opacity = "1"
                this.arButton.style.pointerEvents = "auto"
            }
        },
        updateUserLocation() {
            this.userManualMapControl = false
            this.moveUserIcon()
        },

        async getPoolsCategoriesPrizes() {
            await dropPoolStore().getMapData()
        },
        async getCampaignSegments() {
            await poolSegmentStore().getCampaignSegments()
        },
        getLocations() {
            this.locations = dropPoolStore().getDummyVatLocations
        },
        getDroptype() {
            if (this.activePool) {
                this.dropType = this.activePool.dropType
            }
        },
        initMap() {
            // return new Promise((resolve) => {
            const mapElement = document.getElementById("map")
            if (mapElement) {
                mapElement.style.opacity = "0"
                mapboxgl.accessToken = myAccessToken
                let mapOptions: mapboxgl.MapboxOptions = {
                    container: "map",
                    style: this.mapStyle,
                    center: [0, 0],
                    zoom: 10,
                }
                if (this.activePool?.dropType == "pin") {
                    mapOptions = {
                        container: "map",
                        style: this.mapStyle,
                        center: this.userLocation,
                        zoom: 10,
                    }
                }
                this.map = new mapboxgl.Map(mapOptions)

                setTimeout(() => {
                    if (
                        this.activePool == null &&
                        this.activeDropLocation == null
                    ) {
                        navigationStore().emitAction({
                            type: "open-popup",
                            value: "out-of-drop-zone",
                        })
                    }
                }, 3500)

                this.map.on("dragstart", () => {
                    this.userManualMapControl = true
                })

                this.map.on("load", () => {
                    // this.map!.once("idle", () => {
                    this.map!.addSource("locations-source", {
                        type: "geojson",
                        data: this.geoJson() as any,
                        cluster: true,
                        clusterMaxZoom: 14, // Max zoom to cluster points on
                        clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
                    })

                    this.map!.addLayer({
                        id: "clusters",
                        type: "circle",
                        source: "locations-source",
                        filter: ["has", "point_count"],
                        paint: {
                            "circle-color": "rgba(0, 0, 0, 0)",
                            "circle-radius": 30,
                        },
                    })

                    this.map!.addLayer({
                        id: "cluster-count",
                        type: "symbol",
                        source: "locations-source",
                        filter: ["has", "point_count"],
                        layout: {},
                    })

                    // this.map!.on("render", () => {
                    // 	if (!this.map!.isSourceLoaded("locations-source")) return;
                    // 	this.updateLocations();
                    // });
                    const onClick = async (e: any) => {
                        const features = this.map!.queryRenderedFeatures(
                            e.point,
                            {
                                layers: ["clusters"],
                            }
                        )
                        const clusterId = features[0].properties!.cluster_id
                        const src = this.map!.getSource(
                            "locations-source"
                        ) as any

                        src.getClusterExpansionZoom(
                            clusterId,
                            (err: any, zoom: number) => {
                                if (err) return
                                const geom = features[0].geometry as any
                                this.map!.easeTo({
                                    center: geom.coordinates,
                                    zoom: zoom,
                                })
                            }
                        )
                    }

                    this.map!.on("zoom", () => {
                        const zoom = this.map!.getZoom()

                        if (zoom >= 14 && !this.vatsAdded) {
                            this.vatsAdded = true
                            this.addItemsToMap()
                            this.loadRadiusCircleLayer(this.activeDropLocation)
                        } else if (
                            zoom < 14 &&
                            this.vatsAdded &&
                            this.dropType == "gps"
                        ) {
                            this.vatsAdded = false
                            this.removeItemsFromMap()
                            this.removeRadiusCircleLayer()
                        }
                    })

                    this.map!.on("zoomend", async () => {
                        this.map!.off("click", "clusters", onClick)
                        this.map!.on("click", "clusters", onClick)
                        if (this.initZoom) {
                            this.initZoom = false
                            this.startActiveDropPoolCheck = true
                        }
                    })

                    this.map!.on("sourcedata", () => {
                        // if (!this.map!.isSourceLoaded("locations-source")) return;
                        this.updateLocations()
                    })

                    // resolve(true);
                    // });
                })
            }
            // });
        },
        updateLocations() {
            const features = this.map!.querySourceFeatures("locations-source")
            const newMarkers = {} as { [key: string]: mapboxgl.Marker }

            for (let i = 0; i < features.length; i++) {
                const feature = features[i]
                if (
                    feature.geometry.type == "Point" &&
                    !feature.id &&
                    feature.properties &&
                    feature.properties!.id
                ) {
                    const loc = this.locations.find(
                        (loc: DropLocation) => loc._id == feature.properties!.id
                    )
                    if (!loc) {
                        return
                    }
                    const coords = [loc!.longitude, loc!.latitude]
                    const id = feature.properties!.id
                    let marker = this.locationMarkers[id]
                    if (!marker) {
                        marker = this.locationMarkers[id] = new mapboxgl.Marker(
                            {
                                offset: [0, 0],
                                color: loc!.pinColour ?? "#d51919",
                            }
                        )
                            .setLngLat(coords as [number, number])
                            .setPopup(
                                new mapboxgl.Popup({
                                    closeButton: true,
                                }).setHTML(
                                    `<strong style="margin-bottom:5px;">${
                                        loc!.placeName
                                    }</strong>
									${
                                        loc!.image
                                            ? `<img style="width:100%; height:100px; object-fit:contain; object-position:center;" src="${
                                                  loc!.image
                                              }" />`
                                            : ""
                                    }
									${loc!.description ? `<p style="margin:0 5px;">${loc!.description}</p>` : ""}
									${
                                        loc!.url
                                            ? `<a style="margin:0 5px;" href="${
                                                  loc!.url
                                              }" target="_blank">Website</a>`
                                            : ""
                                    }
									`
                                )
                            )
                    }
                    newMarkers[id] = marker

                    if (!this.locationMarkersOnScreen[id])
                        marker.addTo(this.map!)
                }

                //add cluster
                if (feature.id && feature.geometry.type == "Point") {
                    const id = feature.id + "_marker"
                    const coords = feature.geometry.coordinates
                    let marker = this.locationMarkers[id]
                    if (!marker) {
                        marker = this.locationMarkers[id] = new mapboxgl.Marker(
                            {}
                        ).setLngLat(coords as [number, number])
                    }
                    newMarkers[id] = marker

                    if (!this.locationMarkersOnScreen[id])
                        marker.addTo(this.map!)
                }
                //add number
                if (feature.id && feature.geometry.type == "Point") {
                    const id = feature.id + "_count"
                    const coords = feature.geometry.coordinates
                    let marker = this.locationMarkers[id]
                    if (!marker) {
                        const el = document.createElement("div")
                        el.className = "cluster-count"
                        el.innerText = feature.properties!.point_count
                        marker = this.locationMarkers[id] = new mapboxgl.Marker(
                            el,
                            {
                                offset: [0, -21],
                            }
                        ).setLngLat(coords as [number, number])
                    }
                    newMarkers[id] = marker

                    if (!this.locationMarkersOnScreen[id])
                        marker.addTo(this.map!)
                }
            }
            for (const id in this.locationMarkersOnScreen) {
                if (!newMarkers[id]) this.locationMarkersOnScreen[id].remove()
            }
            this.locationMarkersOnScreen = newMarkers
            if (!this.loadedLocations) {
                const mapElement = document.getElementById("map")
                mapElement!.style.opacity = "1"
                this.loadedLocations = true
                if (this.locations.length > 0) {
                    this.map!.fitBounds(this.locationBounds(), {
                        duration: 0,
                        padding: {
                            top: 100,
                            bottom: 100,
                            left: 100,
                            right: 100,
                        },
                    })
                }
            }
        },
        addUserMarker(): void {
            const user = document.createElement("div")
            user.style.backgroundColor = `rgba(51, 153, 255, 0.3)`
            user.style.width = "50px"
            user.style.height = "50px"
            user.style.borderRadius = "50px"
            user.style.animation = "anim 1s infinite alternate"
            user.style.display = "flex"
            user.style.justifyContent = "center"
            user.style.alignItems = "center"
            const elem = document.createElement("div")
            elem.style.backgroundColor = `#3399ff`
            elem.style.width = "15px"
            elem.style.height = "15px"
            elem.style.borderRadius = "10px"
            elem.style.boxShadow = "2px 2px 3px rgba(0, 0, 0, 0.1) inset"

            user.appendChild(elem)
            if (this.userMarker) {
                this.userMarker.remove()
            }

            this.userMarker = new mapboxgl.Marker({
                draggable: false,
                element: user,
            }).setLngLat(this.userLocation)
            if (this.map) {
                this.userMarker.addTo(this.map)
            }
        },

        async dropItems() {
            if (this.vItems.length == 0 && this.activePool) {
                const walletID = authStore().walletID
                await vItemStore().dropItems({
                    walletID: walletID || "",
                    segmentID: "null",
                    poolID: this.activePool!.id!,
                    userLocation: this.userLocation,
                })
                this.addItemsToMap()
            }
        },
        async addRemoveDroppedItems(poolID: string) {
            await vItemStore().recycleItems({
                walletID: this.walletID!,
                poolID: poolID,
            })
            await this.dropItems()
            this.addItemsToMap()
        },
        moveUserIcon() {
            if (this.map && this.userMarker) {
                this.userMarker.remove()
                this.addUserMarker()
                if (!this.userManualMapControl && !this.mapZooming) {
                    this.mapZooming = true
                    this.map!.flyTo({
                        center: this.userLocation,
                        essential: true,
                        duration: this.mapZoomDuration,
                        zoom: 18,
                    })
                    setTimeout(() => {
                        this.mapZooming = false
                    }, this.mapZoomDuration)
                }
            }
        },
        addItemsToMap(): void {
            if (this.markers.length > 0) {
                for (let i = this.markers.length - 1; i >= 0; i--) {
                    this.markers[i].remove()
                    this.markers.splice(i, 1)
                }
            }

            for (let i = 0; i < this.vItems.length; i++) {
                const item = this.vItems[i]
                const category = categoryStore().getItemByID(item.categoryID)
                const elem = document.createElement("div")
                elem.setAttribute("id", item.id!)
                elem.style.backgroundImage = `url('${item.marker}')`
                elem.style.backgroundSize = "contain"
                elem.style.backgroundPosition = "center"
                elem.style.backgroundRepeat = "no-repeat"
                elem.style.width = `${category!.imageSize}px`
                elem.style.height = `${category!.imageSize}px`
                elem.classList.add("vat-marker")
                const marker = new mapboxgl.Marker({
                    draggable: false,
                    element: elem,
                }).setLngLat([item.longitude, item.latitude])
                this.markers.push(marker)
                // items.push({ _id: i, latitude: coords[i][0], longitude: coords[i][1] });
            }
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].addTo(this.map!)
                this.markers[i].getElement().addEventListener("click", () => {
                    this.selectedMarkerIndex = this.markers[i]
                        .getElement()
                        .getAttribute("id")!
                    navigationStore().emitAction({
                        type: "open-popup",
                        value: "claim-item",
                    })
                })
            }
            if (this.markers.length > 0) {
                this.showARBtn()
            }
        },
        removeItemsFromMap(): void {
            if (this.markers.length > 0) {
                for (let i = this.markers.length - 1; i >= 0; i--) {
                    this.markers[i].remove()
                    this.markers.splice(i, 1)
                }
            }
        },
        loadRadiusCircleLayer(location: DropLocation | null) {
            if (!this.map!.getLayer("radius-circles") && location) {
                const circles: {
                    latitude: number
                    longitude: number
                    radius: number
                }[] = []

                circles.push({
                    latitude: location.latitude,
                    longitude: location.longitude,
                    radius: location.radius! / 1000,
                })

                this.map!.addLayer({
                    id: "radius-circles",
                    type: "fill",
                    source: addDropZoneCircles(
                        circles
                    ) as mapboxgl.AnySourceData,
                    layout: {},
                    paint: {
                        "fill-color": "#2EB3FF",
                        "fill-opacity": 0.2,
                    },
                })
            }
        },
        removeRadiusCircleLayer() {
            if (this.map!.getLayer("radius-circles")) {
                this.map!.removeLayer("radius-circles")
                this.map!.removeSource("radius-circles")
            }
        },
        //Helper functions
        locationBounds(): mapboxgl.LngLatBounds {
            // const bounds: [number, number][] = [];
            const bounds = new mapboxgl.LngLatBounds()

            for (let i = 0; i < this.locations.length; i++) {
                bounds.extend([
                    this.locations[i].longitude,
                    this.locations[i].latitude,
                ])
            }
            return bounds
        },

        async claimItem() {
            const index = this.markers.findIndex(
                (e) =>
                    e.getElement().getAttribute("id") ==
                    this.selectedMarkerIndex
            )
            this.markers[index].remove()
            dropPoolStore().setLoading(true)
            const marker = this.markers.find(
                (e) =>
                    e.getElement().getAttribute("id") ==
                    this.selectedMarkerIndex
            )
            const id = marker?.getElement().getAttribute("id") || ""
            await vItemStore().claimItem(id)

            dropPoolStore().setLoading(false)
            if (this.vItems.length == 0) {
                navigationStore().emitAction({
                    type: "go-to-page",
                    value: "vault",
                })
            }
        },
    },
    computed: {
        //Store getters
        activePool(): DropPool | null {
            return dropPoolStore().getActivePool
        },
        loading(): boolean {
            return dropPoolStore().getLoading
        },
        walletID(): string | null {
            return authStore().getWalletID
        },
        activeDropLocation(): DropLocation | null {
            return dropPoolStore().getActiveDropLocation
        },
        vItems(): VItem[] {
            return vItemStore().getDroppedItems
        },
        action(): Action {
            return navigationStore().getAction
        },
        userLocation(): [number, number] {
            return dropPoolStore().getUserLocation
        },
        locationAccuracy(): number {
            return dropPoolStore().getLocationAccuracy
        },
        watcher(): number {
            return dropPoolStore().getWatcher
        },
    },
})
</script>

<style lang="scss">
#map {
    position: relative;
    height: 100%;
}
.drop-button {
    position: absolute;
    right: 15px;
    top: 15px;
}
.dev-info {
    position: fixed;
    top: 0;
    left: 0;
    color: #fff;
}
@keyframes anim {
    from {
        background-color: rgba(51, 153, 255, 0.3);
    }
    to {
        background-color: rgba(51, 153, 255, 0.1);
    }
}
.cluster-count {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 11px;
    width: 20px;
    height: 20px;
    background-color: #fff;
}
.accuracy-indicator {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: #ff00007b;
    color: #fff;
    padding: 5px 10px;
    border-radius: 100px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
</style>
